<template>
  <div class="UnitDetail bg-white">
    <Row class="px-1">
      <i-col span="24" class="align-justify">
        <Spin size="large" fix v-if="spinShow">
          <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          <div>正在加载...</div>
        </Spin>
        <vue2-org-tree
          :data="treeData"
          :horizontal="true"
          collapsable
          :render-content="renderContent"
          @on-expand="onExpand"
          @on-node-click="NodeClick"
        />
      </i-col>
    </Row>
    <Modal v-model="nodeShow" width="540" :mask-closable="false">
      <p slot="header">
        <span>详细信息</span>
      </p>
      <div>
        <Form :model="orgInfo">
          <Row :gutter="32">
            <i-col span="24">
              <FormItem label="名称" label-position="top">
                <Input v-model="orgInfo.companyName" />
              </FormItem>
            </i-col>
            <i-col span="24">
              <FormItem label="地址" label-position="top">
                <Input type="textarea" v-model="orgInfo.companyAddress" />
              </FormItem>
            </i-col>
            <i-col span="24">
              <FormItem label="电话" label-position="top">
                <Input v-model="orgInfo.companyTel" />
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="联系人" label-position="top">
                <Input v-model="orgInfo.contactUser" />
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="联系人电话" label-position="top">
                <Input v-model="orgInfo.contactPhone" />
              </FormItem>
            </i-col>
            <i-col span="24">
              <FormItem label="验证码" label-position="top">
                <Input v-model="orgInfo.verifyCode" readonly>
                  <Button
                    type="dashed"
                    :loading="refreshLoading"
                    slot="append"
                    icon="ios-refresh"
                    @click="refreshVerifyCode"
                  ></Button>
                </Input>
              </FormItem>
            </i-col>
            <!-- <i-col span="12">
              <FormItem label="验证码过期时间" label-position="top">
                <DatePicker
                  v-model="orgInfo.verifyTime"
                  type="date"
                  style="display: block"
                  placement="bottom-end"
                ></DatePicker>
              </FormItem>
            </i-col>-->
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button type="primary" :loading="upd_loading" @click="updateOrg">修改</Button>
      </div>
    </Modal>

    <Modal
      v-model="showAddWindow"
      scrollable
      footer-hide
      title="添加单位用户"
      ok-text="确定添加"
      cancel-text="取消"
      width="540"
      :mask-closable="false"
      :loading="modalLoading"
    >
      <i-form :model="companyInfo">
        <Row :gutter="5" class="px-1">
          <i-col span="24">
            <Row :gutter="8">
              <i-col span="24">
                <FormItem label="单位名称">
                  <i-input v-model="companyInfo.CompanyName"></i-input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="单位联系电话">
                  <i-input v-model="companyInfo.CompanyTel"></i-input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="联系人" style="text-align: left;">
                  <i-input v-model="companyInfo.ContactUser"></i-input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="验证码" label-position="top">
                  <Input v-model="companyInfo.VerifyCode" readonly>
                    <Button
                      type="dashed"
                      :loading="refreshLoading"
                      slot="append"
                      icon="ios-refresh"
                      @click="refreshVerifyCodeAdd"
                    ></Button>
                  </Input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="联系人电话" style="text-align: left;">
                  <i-input v-model="companyInfo.ContactPhone" maxlength="11"></i-input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="联系地址" style="text-align: left;">
                  <i-input v-model="companyInfo.CompanyAddress" type="textarea"></i-input>
                </FormItem>
              </i-col>
            </Row>
            <Button
              type="primary"
              style="float:right;margin-left:10px;"
              :loading="modalLoading"
              @click="insertCompany"
            >确认添加</Button>
          </i-col>
        </Row>
      </i-form>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "UnitDetail",
  data() {
    return {
      companyInfo: {
        CompanyName: "",
        CompanyTel: "",
        CompanyAddress: "",
        ContactUser: "",
        ContactPhone: "",
        VerifyCode: "",
      },
      showAddWindow: false,
      modalLoading: false,
      refreshLoading: false,
      nodeShow: false,
      add_loading: false,
      del_loading: false,
      upd_loading: false,
      spinShow: true,
      treeData: {},
      theight: window.innerHeight - 175,

      orgInfo: {},
      unitId: 1,
      checkLevel: -1,
      checkUnitId: -1,
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
    if (this.$route.query.unitId == undefined) {
    } else {
      this.unitId = this.$route.query.unitId;
    }
    this.getOrgTree();
  },
  methods: {
    renderContent(h, data) {
      return h(
        "Dropdown",
        {
          attrs: {
            trigger: "click",
          },
        },
        [
          h(
            "a",
            {
              domProps: {
                href: "javascript:;",
              },
              on: {
                click: () => {
                  this.showFlag = data.children ? true : false;
                },
              },
            },
            data.label
          ),
          h(
            "DropdownMenu",
            {
              slot: "list",
            },
            [
              h(
                "DropdownItem",
                {
                  nativeOn: {
                    click: () => {
                      this.update(data);
                    },
                  },
                },
                "查看信息"
              ),
              h(
                "DropdownItem",
                {
                  nativeOn: {
                    click: () => {
                      this.add(data);
                    },
                  },
                },
                "添加子级"
              ),
              h(
                "DropdownItem",
                {
                  nativeOn: {
                    click: () => {
                      this.del(data);
                    },
                  },
                },
                "删除此项"
              ),
            ]
          ),
        ]
      );
    },
    add(data) {
      this.checkLevel = data.level;
      this.checkUnitId = data.id;
      this.showAddWindow = true;
    },
    del(data) {
      if (data.level == 1) {
        this.$Message.error("一级单位不允许删除");
        return;
      }
      this.$Modal.confirm({
        title: "警告",
        content: "<p>下级单位会被一起删除,确定删除?</p>",
        loading: true,
        onOk: () => {
          this.$post("Customer/DeleteCompany", {
            CompanyId: data.id,
          }).then((res) => {
            if (res.data.code) {
              this.$Modal.remove();
              this.$Message.success(res.data.msg);
              this.getOrgTree();
            } else {
              this.$Modal.remove();
              this.$Message.error(res.data.msg);
            }
          });
        },
        onCancel: () => {},
      });
    },
    update(data) {
      this.checkLevel = data.level;
      this.checkUnitId = data.id;
      this.nodeShow = true;
    },
    updateOrg() {
      this.upd_loading = true;
      if (this.orgInfo.companyName == "") {
        this.upd_loading = false;
        this.$Message.error({
          content: "单位名称不得为空",
          duration: 2,
        });
        return;
      }
      this.$post("Customer/UpdateCompany", {
        CompanyName: this.orgInfo.companyName,
        CompanyTel: this.orgInfo.companyTel,
        CompanyAddress: this.orgInfo.companyAddress,
        ContactUser: this.orgInfo.contactUser,
        ContactPhone: this.orgInfo.contactPhone,
        VerifyCode:
          this.orgInfo.verifyCode != null
            ? this.orgInfo.verifyCode.toString()
            : "",
        CompanyId: this.orgInfo.companyId,
      })
        .then((res) => {
          this.upd_loading = false;
          if (res.data.code) {
            this.$Message.success({
              content: "修改成功",
              duration: 2,
            });
            this.nodeShow = false;
            this.getOrgTree();
          } else {
            this.$Message.error({
              content: "修改失败，请重试",
              duration: 2,
            });
          }
        })
        .catch((error) => {
          this.upd_loading = false;
        });
    },
    insertCompany() {
      if (this.companyInfo.CompanyName == "") {
        this.modalLoading = false;
        this.$Message.error({
          content: "单位名称不得为空",
          duration: 2,
        });
        return;
      }
      this.$post("Customer/InsertCompany", {
        CompanyName: this.companyInfo.CompanyName,
        CompanyTel: this.companyInfo.CompanyTel,
        CompanyAddress: this.companyInfo.CompanyAddress,
        ContactUser: this.companyInfo.ContactUser,
        ContactPhone: this.companyInfo.ContactPhone,
        VerifyCode: this.companyInfo.VerifyCode.toString(),
        Level: this.checkLevel + 1,
        ParentId: this.checkUnitId,
      })
        .then((res) => {
          this.modalLoading = false;
          if (res.data.code) {
            this.$Message.success({
              content: "添加成功",
              duration: 2,
            });
            this.$Modal.remove();
            this.showAddWindow = false;
            this.getOrgTree();
          } else {
            this.$Modal.remove();
            this.$Message.error({
              content: "添加失败，请重试",
              duration: 2,
            });
          }
        })
        .catch((error) => {
          this.modalLoading = false;
        });
    },
    getProjectNum() {
      const projectTime = new Date(); // 当前中国标准时间
      const Year = projectTime.getFullYear(); // 获取当前年份 支持IE和火狐浏览器.
      const Month = projectTime.getMonth() + 1; // 获取中国区月份
      const Day = projectTime.getDate(); // 获取几号
      var CurrentDate = Year;
      if (Month >= 10) {
        // 判断月份和几号是否大于10或者小于10
        CurrentDate += Month;
      } else {
        CurrentDate += "0" + Month;
      }
      if (Day >= 10) {
        CurrentDate += Day;
      } else {
        CurrentDate += "0" + Day;
      }
      return CurrentDate;
    },
    refreshVerifyCodeAdd() {
      this.refreshLoading = true;
      this.companyInfo.VerifyCode = new Date().getTime();
      this.refreshLoading = false;
    },
    refreshVerifyCode() {
      this.refreshLoading = true;
      this.orgInfo.verifyCode = new Date().getTime();
      this.refreshLoading = false;
      this.$Message.success("Successfully delete");
    },
    getOrgTree() {
      this.spinShow = true;
      this.$get("Customer/GetUnitOrgTree", { unitId: this.unitId }).then(
        (res) => {
          this.treeData = res.data.response;
          this.toggleExpand(this.treeData, true);
        }
      );
    },
    NodeClick(e, data) {
      this.$get("Customer/GetUnitDetail", { unitId: data.id }).then((res) => {
        this.orgInfo = res.data.response;
      });
    },
    toggleExpand(data, val) {
      var _this = this;
      if (Array.isArray(data)) {
        data.forEach(function (item) {
          _this.$set(item, "expand", val);
          if (item.children) {
            _this.toggleExpand(item.children, val);
          }
        });
      } else {
        this.$set(data, "expand", val);
        if (data.children) {
          _this.toggleExpand(data.children, val);
        }
      }
      this.spinShow = false;
    },
    collapse(list) {
      var _this = this;
      list.forEach(function (child) {
        if (child.expand) {
          child.expand = false;
        }
        child.children && _this.collapse(child.children);
      });
    },
    onExpand(e, data) {
      if ("expand" in data) {
        data.expand = !data.expand;
        if (!data.expand && data.children) {
          this.collapse(data.children);
        }
      } else {
        this.$set(data, "expand", true);
      }
    },
  },
};
</script>

<style lang="less">
.org-tree-node-label:hover {
  cursor: pointer;
}
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
